<template>
  <div class="partners-top-banner">
    <div style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
    class="top-banner-container company-top-banner-container w-container">
    <h1 class="white-text-align-left">Serviço de guincho<br><br><br><strong>Proximo de Você!</strong></h1>
    <h5 class="white-text-align-left">Confiança é essencial, e estamos aqui para ser seu parceiro em qualquer momento de necessidade. Conte com nosso serviço de guincho para tranquilidade e segurança em todas as estradas. Sua jornada importa para nós!<br></h5>

  </div>
  <br><br><br>
  <div style="display: grid;row-gap: 5rem;">
  <div class="">
  <a href="https://api.whatsapp.com/send?phone=5535997012939&amp;text=Olá%20Minas%20Auto%20Socorro,%20gostaria%20de%20um%20orçamento." target="_blank" class="btn-whats btn" rel="noopener noreferrer"><span>CHAME O GUINCHO PELO WHATSAPP</span></a>

  </div>
  <div class="">
  <a href="tel:35997012939" target="_blank" class="btn-tel btn" rel="noopener noreferrer"><span>LIGUE PARA O GUINCHO 24 HORAS</span></a>

  </div>
</div>

</div></template>
<script>

export default {
  name: 'banner-cps'
}
</script>
<style lang="scss" scoped>
.partners-top-banner {
    background-image: linear-gradient(rgba(3,3,3,.7),rgba(3,3,3,.7)),url('~@/assets/banner01.jpg');
    background-position: 0 0,50%;
    background-repeat: repeat,no-repeat;
    background-size: auto,cover;
    @media only screen and (max-width: 600px) {
      padding:18vw 0!important;
    }
    padding-top: 235px;
    padding-bottom: 230px;
}
.top-banner-container{
 margin-bottom: 3rem!important;
 h5{
      margin: 2rem auto!important;
      @media only screen and (max-width: 600px) {
        padding: 0 1rem!important;
        text-align: left!important;
        text-justify: distribute;
      }
}
.white-text-align-left {

   strong{
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ff0000, 0 0 40px #ff0000, 0 0 50px #ff0000, 0 0 60px #ff0000, 0 0 70px #ff0000;
    letter-spacing: 0.05em;
    font-size: 100px;
    @media only screen and (max-width: 600px) {
      margin-top: 5rem!important;
      font-size: 50px;
    }

   }
   color: #fff;
   }
}
h1 {
    color: #131c21;
    font-family: Poppins,sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 600px;
}
h1,h5 {
    margin-top: 2rem!important;
    font-size: 32px;
    line-height: 44px;
}
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px!important;
    font-weight: 700;
}
h1 {
    margin: .67em 0!important;
    font-size: 2em;
}
h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em!important;
    margin-block-end: 0.67em!important;
    margin-inline-start: 0px!important;
    margin-inline-end: 0px!important;
    font-weight: bold;
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 65px;
}

}
</style>
