<template>
  <div class="home">
    <Banner/>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '@/components/Banner.vue'
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'home-cps',
  components: {
    Banner,
    HelloWorld
  }
}
</script>
