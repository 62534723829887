<template>
  <div class="hello">
    <section>
      <ul>
        <li>
          <img src="@/assets/reboque.webp" alt="" width="100" height="100">
          <h2>REBOQUE 24 HORAS</h2><br><p>Sempre Prontos Para Resgatar Você, a Qualquer Hora!</p>
        </li>
        <li><img src="@/assets/pneu.webp" alt="" width="100" height="100"><h2>TROCA DE PNEU</h2><br><p>Confiança e Prontidão a Seu Lado na Estrada!</p></li>
        <li><img src="@/assets/galao.webp" alt="" width="68" height="100"><h2>PANE SECA</h2><br><p>Supere esse Desafio com o Nossos Serviço de Guincho Rápido e Eficiente!</p></li>
        <li><img src="@/assets/bateria.webp" alt="" width="100" height="100"><h2>CARGA NA BATERIA</h2><br><p>Ajudando Você a Continuar na Estrada com Velocidade e Mobilidade!</p></li>
      </ul>
    </section>
    <div
      style="opacity: 1;padding: 3rem!important; background: #4E5A61; color:#fff!important;"
      class="help-businesses">
      <div class="title-background">
        <p class="subtitle services-title">MINAS AUTO SOCORRO</p>
      </div>
      <h2 class="title-headind help-businesses-title">Carro Quebrado? Descubra Soluções Rápidas para Continuar na Estrada!</h2>
      <div class="title-info-text help-businesses-description">Conte conosco para resolver essa situação e voltar à estrada com segurança e tranquilidade. Ligue agora para a assistência profissional que você precisa!. Clique no botão abaixo para solicitar um serviço de guincho imediatamente..</div>
      <a target="_blank" href="tel:35997012939"
        class="btn btn-tel"><img src="@/assets/phone-32.svg" alt="" width="16" height="16">&nbsp;<span>LIGUE PARA O GUINCHO</span></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* .help-businesses{
  *{
    margin-bottom: 2rem!important;
  }

} */
ul{
  li{
    padding:3rem!important;
  }
  display: grid;
  padding:0!important;
  grid-template-columns: repeat(4,calc(100%/4));
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1,80%);
    align-items: center;
    justify-content: center;
  }
}
.title-headind {
    max-width: 750px;
    text-align: center;
    @media only screen and (max-width: 600px) {
      text-align: left!important;
    }
    flex: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: Poppins,sans-serif;
    font-size: 32px;
    line-height: 1.2;
}
.btn-banner{
  min-width: 70vw!important;
  @media only screen and (max-width: 600px) {
    min-width: 70vw!important;
  }
}
.title-background {
    height: 30px;
    max-width: 30vw!important;
    @media only screen and (max-width: 600px) {
      max-width: 90vw!important;
    }
    background-color: #ff0000;
    color:#fff;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: skew(-15deg);
    margin:2rem auto!important;
    padding: 2rem!important;
}

.testimonials-partners {
  width: 100%;
  max-width: 1170px;
  margin: 70px auto;
}

.partner-container {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
}

.partner-link {
  width: 100%;
  height: 100%;
  min-height: 130px;
  filter: brightness(0%);
  transition: filter 30ms;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.partner-link.partner-1 {
  background-image: url(https://assets.website-files.com/60accd6bf378570a40585e3a/60b74c0264e9e7233d04c54f_IT-logo-1.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  transition: filter .2s;
  display: inline-block;
}
.button {
    min-width: 155px;
    cursor: pointer;
    background-color: #ff0000;
    padding: 21px 32px;
    font-family: Poppins,sans-serif;
    font-weight: 400;
    line-height: 1.15;
    transition: background-color .25s;
}
.w-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    padding: 9px 15px;
    text-decoration: none;
    display: inline-block;
}
.title-info-text.help-businesses-description {
    max-width: 550px;
    margin:50px auto!important;
}
.title-info-text {
    max-width: 785px;
    text-align: center;
    @media only screen and (max-width: 600px) {
      text-align: left!important;
    }
    margin-top: 10px;
    margin-bottom: 50px;
    font-family: Poppins,sans-serif;
    font-size: 18px;
    line-height: 1.67em;
}
</style>
