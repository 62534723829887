<template>
  <div class="navbar w-nav" style="background-color: rgba(0, 0, 0, 0);">
    <div class="w-container">
      <a href="#" class="brand w-nav-brand"><img
        src="@/assets/logo.png" alt="Author" class="navigation-logo-image-1">
      </a>
  </div>
</div></template>
<script>
export default {
  name: 'nav-cps'
}
</script>
<style lang="scss" scoped>
.brand{
  position: relative;
  float: left;
}
.w-container{
  background: #fff;
  max-height: 50px!important;
  min-width: 100vw!important;
  @media only screen and (max-width: 600px) {
  min-height: 7vh!important;
  }
  padding: 2rem!important;
}
</style>
