<template>
  <Nav />
  <section class="r-view"><router-view /></section>
  <Footer />
</template>

<style lang="scss">
* {
  text-decoration: none;
  list-style: none;
  text-align: center !important;
  margin: 0 auto !important;
  box-sizing: border-box !important;
  max-width: 100vw !important;
  @media only screen and (max-width: 600px) {
    font-size: 4.5vw!important;
    h1 , h5, h2 {
      font-size: 8vw!important;
    }
    h5{
      text-justify: inter-word;
    }
    strong{
      font-size: 12vw!important;
    }
  }
}

.r-view {
  margin: 3rem 0 !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.btn{
  position: relative;
  margin: 1rem 5rem !important;
  opacity: 1;
  padding: 10px 30px 10px 30px !important;
  border-radius: 99px;
  min-width: 20vw;
  @media only screen and (max-width: 600px) {
    padding: 20px 2vw 20px 2vw !important;
    margin: 0 !important;
    min-width: 100vw!important;
  }
}
.btn-whats{
    background: #3cff00cc;
    color: #fff;
    font-weight: bold;
    box-shadow: 0 0 15px #fff, 0 0 15px #fff, 0 0 10px #3cff00cc, 0 0 20px #3cff00cc, 0 0 30px #3cff00cc, 0 0 10px #3cff00cc, 0 0 10px #3cff00cc;
  }
  .btn-tel{
    background: #1a73e8;
    color: #fff;
    font-weight: bold;
    box-shadow: 0 0 15px #fff, 0 0 15px #fff, 0 0 10px #1a73e8, 0 0 20px #1a73e8, 0 0 30px #1a73e8, 0 0 10px #1a73e8, 0 0 10px #1a73e8;
  }
</style>
<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import Nav from '@/components/Nav.vue'

export default {
  name: 'app-main',
  components: {
    Nav,
    Footer
  }
}

</script>
